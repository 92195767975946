import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PhotosSwiping from '../PhotosSwiping';
import DetailComponent1 from './DetailComponent1'; // Import your detail components
import DetailComponent2 from './DetailComponent2';
import DetailComponent3 from './DetailComponent3';

const DetailComponent = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" component={PhotosSwiping} />
        <Route path="/detail1" component={DetailComponent1} /> {/* Route for DetailComponent1 */}
        <Route path="/detail2" component={DetailComponent2} /> {/* Route for DetailComponent2 */}
        <Route path="/detail3" component={DetailComponent3} /> {/* Route for DetailComponent3 */}
      </Routes>
    </Router>
  );
};

export default DetailComponent;
