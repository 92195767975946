import React, { useState, useEffect, useRef } from 'react';
import './AboutWe.css';
import image1 from './AboutWeImages/Main1.jpg';
import image2 from './AboutWeImages/Main2.png';
import logo from './LogoFE.png'; // Import your logo image

const AboutWe = () => {
  const slides = [
    {
      id: 1,
      heading: 'OUR MISSION',
      text: 'Pooling the Right resources who will be associated with us in our journey to achieve our vision',
      headingClassName: 'mission-heading',
      textClassName: 'mission-text'
    },
    {
      id: 2,
      heading: 'OUR VISION',
      text: 'Be a pioneer in cutting edge of the Industry’s expectations',
      headingClassName: 'vision-heading',
      textClassName: 'vision-text'
    },
    {
      id: 3,
      heading: 'OUR OBJECTIVES',
      text: 'Raise awareness among business owners about the advantages of software and ERP systems, guiding them to leverage computerization for maximum benefit. Aim to deliver meaningful and customized software solutions at an affordable price, contributing to wealth creation for the company',
      headingClassName: 'objectives-heading',
      textClassName: 'objectives-text'
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5500);

    return () => clearInterval(interval);
  }, [slides.length]);

  const handleDragStart = (e) => {
    setDragging(true);
    setDragStartX(e.clientX);
  };

  const handleDragMove = (e) => {
    if (dragging) {
      const dragDistance = e.clientX - dragStartX;
      const slideWidth = sliderRef.current.offsetWidth;
      if (dragDistance > slideWidth / 3) {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
        setDragging(false);
      } else if (dragDistance < -slideWidth / 3) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        setDragging(false);
      }
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  return (
    <div className="container">
      <div className="image-container">
        <img className='mainBg' src={image1} alt="Main Background" />
        <img className='sideBg' src={image2} alt="Side Background" />
      </div>
      <div
        className="text-gallery"
        ref={sliderRef}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
      >
        <img className="logofe" src={logo} alt="Logo" />
        <div className="rocket-heading">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`slide ${index === currentSlide ? 'active' : ''}`}
            >
              <h2 className={slide.headingClassName}>{slide.heading}</h2>
              <p className={slide.textClassName}>{slide.text}</p>
            </div>
          ))}
        </div>
        <div className="slide-indicators">
          {slides.map((slide, index) => (
            <span
              key={slide.id}
              className={`indicator-dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutWe;
