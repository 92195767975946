import React from 'react';
import './App.css';
import HomeLayout from './Home/HomeLayout';
import Navigation from './Home/Navigation';
import PhotoSwiping from './Home/PhotosSwiping';
import DetailComponent from './Home/GalleryButtons/DetailComponent';
import LogoPage from './Home/LogoPage';
import AboutWe from './Home/AboutWe';
import WorkDone from './Home/WorkDone';
import Implementation from './Home/Implementation';
import LastContact from './Home/LastContact';


function App() {
  return (
    <div className="App">
      <HomeLayout />
      <Navigation />
      <PhotoSwiping />
      <DetailComponent />
      <LogoPage />
      <AboutWe />
      <WorkDone />
      <Implementation />
      <LastContact />
    </div>
  );
}

export default App;
