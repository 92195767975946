// DetailComponent1.js
import React from 'react';

const DetailComponent2 = () => {
  return (
    <div>
      <h2>Details for Image 1</h2>
      {/* Add specific details for image 1 */}
    </div>
  );
};

export default DetailComponent2;
