import React from "react";
import "./HomeLayout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';



const HomeLayout = () => {
    return (
      <div className="top-header">
        <FontAwesomeIcon icon={faPhone} className="phone-icon" />
        <p className="info">Talk With Our Team : <span className="contact"><b>+91 95000 88943 </b></span></p>
        <FontAwesomeIcon icon={ faEarthAmericas }  flip className="globe" />
        <a className="side-info" href="#"><b>One point Service Provider for all your software needs</b></a>
      </div>
    );
  };
  
  export default HomeLayout;