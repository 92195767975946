import React from 'react';
import './LogoPage.css';
import image1 from './PhotoGallery/image-1.jpg';
import logo1 from './LogoImages/afi.png';
import logo2 from './LogoImages/amar.png';
import logo3 from './LogoImages/bd.png';
import logo4 from './LogoImages/cavin.png';
import logo5 from './LogoImages/chak.png';
import logo6 from './LogoImages/iig.png';
import logo7 from './LogoImages/in.png';
import logo8 from './LogoImages/nour.png';
import logo9 from './LogoImages/piri.png';
import logo10 from './LogoImages/primetec.png';
import logo11 from './LogoImages/shel.png';
import logo12 from './LogoImages/skating.png';
import logo13 from './LogoImages/tvs.png';
import ilogo from './LogoFE.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const LogoPage = () => {
  return (
    <div>
        <div className='headLogo'>
      <img className='ilogo' src={ilogo} alt='ilogo' /> {/* Use the correct icon object */}
      <h1>OUR CLIENTS</h1>
      <FontAwesomeIcon className='chevronupLP' icon={faChevronUp} />
            <FontAwesomeIcon className='minusLP' icon={faMinus} />
            <FontAwesomeIcon className='minussLP' icon={faMinus} />
      </div>
      <img className='logoBg' src={image1} alt="Client Background" />
      
      <img className='afi' src={logo1} alt='afi' />
      <img className='amar' src={logo2} alt='amar' />
      <img className='bd' src={logo3} alt='bd' />
      <img className='cavin' src={logo4} alt='cavin' />
      <img className='chak' src={logo5} alt='chak' />
      <img className='iig' src={logo6} alt='iig' />
      <img className='in' src={logo7} alt='in' />
      <img className='nour' src={logo8} alt='nour' />
      <img className='piri' src={logo9} alt='piri' />
      <img className='primetec' src={logo10} alt='primetec' />
      <img className='shel' src={logo11} alt='shel' />
      <img className='skating' src={logo12} alt='skating' />
      <img className='tvs' src={logo13} alt='tvs' />
    </div>
  );
};

export default LogoPage;
