import React from 'react';
import './WorkDone.css';
import Logoo from './LogoFE.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const WorkDone = () => {
    return (
        <div className='heading1'>
            <h1 className='workdoneh1'>Why Choose Us</h1>
            <FontAwesomeIcon className='chevronup' icon={faChevronUp} />
            <FontAwesomeIcon className='minusssWD' icon={faMinus} />
            <FontAwesomeIcon className='minussWD' icon={faMinus} />
        <div className="container1">
        <img className='logooWD' src={Logoo} alt='Logo' />
            <div className="square1">
                <h2><span className='workdoneh21'>16+ Years Experience </span><br />
                          In Software Industry<br />
                    <span className='workdoneh21'>Determination</span><br /></h2>
                    <FontAwesomeIcon className='minusWD' icon={faMinus} />
                <p >We are determined to do only customized software development (tailor made to the user need) and always deploy our software solutions only on cloud for obvious reasons</p>
            </div>
            <div className="square2">
                <h2><span className='workdoneh22'>Experienced/Talented </span><br />
                    Software Professionals<br />
                    <span className='workdoneh22'>Collaboration </span><br /></h2>
                    <FontAwesomeIcon className='minusWD' icon={faMinus} />
                <p>We understand the need of our customers and collaborate with them to bring out the desired results that would meet their expectations</p>
            </div>
            <div className="square3">
                <h2><span className='workdoneh23'>Proven Methodology</span><br />
                    for Deliverables<br />
                    <span className='workdoneh23'>Improvement</span><br /></h2>
                    <FontAwesomeIcon className='minusWD' icon={faMinus} />
                <p>Our work does not stop with the implementation of the software. We always associate with our customers and as part of the post implementation process, and also consistently take effort in improving the software to make it more user friendly</p>
            </div>
            <div className="square4">
                <h2><span className='workdoneh24'>After Sales Support </span><br />
                    To our valuable Clients<br />
                    <span className='workdoneh24'>Philosophy  </span><br /></h2>
                    <FontAwesomeIcon className='minusWD' icon={faMinus} />
                <p>We strongly believe in the philosophy that<span className='king'><b> “Customer is the King” </b></span> and they are the ambassador for us to promote our products & services once they are comfortable with us
</p>
            </div>
        </div>
      
        </div>
    );
};

export default WorkDone;