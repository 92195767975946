import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './LastContact.css';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const LastContact = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const sliderRef = useRef(null);
  const slideWidthRef = useRef(0);

  // Define your slide content
  const slides = [
    {
      heading: (
        <>
          Registered <span className="styled-word">Office</span>
        </>
      ),
      text: (
        <>
          27/85 Thendral Street, <br /> Kumaran Nagar Extn 2, Padi,<br />
          Chennai 600050, India
        </>
      ),
    },
    {
      heading: (
        <>
          Work <span className="styled-word">Place</span>
        </>
      ),
      text: (
        <>
          27/85 Thendral Street, <br /> Kumaran Nagar Extn 2, Padi,<br />
          Chennai 600050, India
        </>
      ),
    },
  ];

  // Function to change slides automatically
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  // Automatically change slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  // Calculate slide width on initial render
  useEffect(() => {
    if (sliderRef.current) {
      slideWidthRef.current = sliderRef.current.offsetWidth;
    }
  }, []);

  // Handle drag start
  const handleDragStart = (e) => {
    setDragging(true);
    setDragStartX(e.clientX);
  };

  // Handle drag move
  const handleDragMove = (e) => {
    if (dragging && sliderRef.current) {
      const dragDistance = e.clientX - dragStartX;
      const dragPercentage = dragDistance / slideWidthRef.current;
      const threshold = 0.15; // Adjust threshold as needed
      if (dragPercentage > threshold) {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
        setDragging(false);
      } else if (dragPercentage < -threshold) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        setDragging(false);
      }
    }
  };

  // Handle drag end
  const handleDragEnd = () => {
    setDragging(false);
  };

  // Function to handle indicator dot click
  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="containerLC">
        <h1 className='ConHeadLC'>Useful Links</h1>
        <FontAwesomeIcon className='minusLC' icon={faMinus} />
      {/* Hyperlinks */}
      <div className="hyperlinksLC">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
        <a href="#">Link 4</a>
        <a href="#">Link 5</a>
        <a href="#">Link 6</a>
        <a href="#">Link 7</a>
        <a href="#">Link 8</a>
      </div>

      {/* Div with heading, paragraph, and form */}
      <div className="contentLC">
      <h2 className='HeadingLC'>Reach Us</h2>
      <FontAwesomeIcon className='minusLCC' icon={faMinus} />
      <p className='ParaLC'>
        <b>Email:</b> info@5brains.com,<br /> customercare@5brains.com <br />
        <b>Phone:</b> +91 95000 88943, +91 44 4958 3823
      </p>
      <form action="#" method="POST" className="email-formLC">
        <div className="input-containerLC">
          <input type="email" id="email" name="email" placeholder='Email Address..' required />
          <button type="submit" className="arrow-buttonLC">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </form>
      <FontAwesomeIcon className='minusLCCCC' icon={faMinus} />
    </div>

    {/* New heading above the text slider */}
    <div className="text-slider-heading">
      <h2 className="slider-headingLC">Address</h2>
      <FontAwesomeIcon className='minusLCCC' icon={faMinus} />
    </div>

    {/* Text Gallery */}
    <div
      className="text-galleryLC"
      ref={sliderRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`text-slideLC ${index === currentSlide ? 'active' : ''}`}
          style={{ transform: `translateX(${100 * (index - currentSlide)}%)` }}
        >
          <h3>{slide.heading}</h3>
          <p>{slide.text}</p>
        </div>
      ))}
    </div>

    {/* Slide Indicator with Circles */}
    <div className="slide-indicatorLC">
      {slides.map((_, index) => (
        <div
          key={index}
          className={`indicator-dotLC ${index === currentSlide ? 'active' : ''}`}
          onClick={() => handleIndicatorClick(index)}
        />
      ))}
    </div>
  </div>
);
};
export default LastContact;
