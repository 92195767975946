import React, { useState, useEffect } from 'react';
import './PhotosSwiping.css';
import image1 from './PhotoGallery/image-1.jpg';
import overlay1 from './PhotoGallery/pg1.png';
import overlay2 from './PhotoGallery/pg2.png';
import overlay3 from './PhotoGallery/pg3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';


const PhotosSwiping = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const images = [
    {
      src: image1,
      overlaySrcs: [overlay1],
      overlayText: (
        <>
          <span className="image444">ERP</span>
          <br /> <span className="image111">Enterprise Resource Planning for MSME </span>
          <br />
        </>
      ),
      readMoreText: 'Read More 1',
      additionalText: (
        <>
          ERP for MSME manages all aspects of business,<br /> including production, planning, purchase, sales,<br /> finance,
          and customer service, from one <br /> fully-integrated system. <br />
        </>
      ),
      textAnimationClass: 'text-animation-1', // Class for text animation
    },
    {
      src: image1,
      overlaySrcs: [overlay2],
      overlayText: (
        <>
          <span className="image555">ERP</span>
          <br /> <span className="image222">Custom Solutions for any specific module(s)</span> <br />
        </>
      ),
      readMoreText: 'Read More 2',
      additionalText: (
        <>
          Each ERP module is designed for specific business <br /> functions that help do their jobs. Every module <br /> plugs into
          the ERP system and provides accurate <br />  data, even as business adds new modules. <br />
        </>
      ),
      textAnimationClass: 'text-animation-2', // Class for text animation
    },
    {
      src: image1,
      overlaySrcs: [overlay3],
      overlayText: (
        <>
          <span className="image666">Custom</span> <br /> <span className="image333">Software Solution</span>
        </>
      ),
      readMoreText: 'Read More 3',
      additionalText: (
        <>
          Development of custom software is a process of <br /> designing software applications that meet the <br/> specific needs
           of an individual or a company. <br />
        </>
      ),
      textAnimationClass: 'text-animation-3', // Class for text animation
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
      setCurrentSlide(0); // Reset slide to 0 when switching images
      setShowMore(false); // Hide "Read More" text when switching images
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    setCurrentSlide(0); // Reset slide to 0 when switching images
    setShowMore(false); // Hide "Read More" text when switching images
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setCurrentSlide(0); // Reset slide to 0 when switching images
    setShowMore(false); // Hide "Read More" text when switching images
  };

  const handleImageClick = (index) => {
    setCurrentSlide(index);
    setShowMore(false); // Hide "Read More" text when clicking on image
  };

  const handleReadMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="image-gallery-container">
      <div className={`parent-image-container fade-in-animation`}>
        <img src={images[currentImage].src} alt={`Image ${currentImage + 1}`} className="main-image" />
        {images[currentImage].overlaySrcs.map((overlaySrc, index) => (
          <img
            key={index}
            src={overlaySrc}
            alt={`Overlay Image ${index + 1}`}
            className={`overlay-image overlay-${index} ${images[currentImage].textAnimationClass}`}
          />
        ))}
        <div className={`overlay-text overlay-text-${currentSlide} ${images[currentImage].textAnimationClass}`}>
          {images[currentImage].overlayText}
        </div>

        <div className={`additional-text ${images[currentImage].textAnimationClass} ${showMore ? 'show-more' : ''}`}>
          {images[currentImage].additionalText}
        </div>

        <button className="readmore-button" onClick={handleReadMoreClick}>
          {showMore ? 'Show Less' : images[currentImage].readMoreText}
        </button>
      </div>
      <div className="navigation-arrows">
        <i className="fas fa-chevron-left arrow-icon left-arrow" onClick={prevImage}></i>
        <i className="fas fa-chevron-right arrow-icon right-arrow" onClick={nextImage}></i>
      </div>
      <div className="slide-indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`slide-indicator ${currentImage === index ? 'active' : ''}`}
            onClick={() => setCurrentImage(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};
export default PhotosSwiping;