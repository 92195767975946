import React from 'react';
import './Implementation.css';
import bgImpp from './bg2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons';
import logoimp from './LogoFE.png';


const Implementation = () => {
  return (
    <div className="main-containerI">
        <h1 className='HeadingImp'>What We Do</h1>
        <img
  className='bgImp'
  src={bgImpp}
  alt='Background'
/>
<img className='logoimpp' src={logoimp} alt='Logo' />
<FontAwesomeIcon className='arrow1' icon={faArrowTurnDown} />
<FontAwesomeIcon className='arrow2' icon={faArrowTurnDown} />
<FontAwesomeIcon className='arrow3' icon={faArrowTurnDown} />
      <div className="containerI1">
        <h2 className='HeadI1'>5b-Sulabha AI integrated ERP (Enterprise Resource Planning)</h2>
        <p className='ParaI1'>ERP offers flexible implementation based on user needs and budget. It facilitates information sharing across departments for efficiency. With a user-friendly interface and comprehensive reports, it improves inventory management, reduces costs, and optimizes human resources. Including a customer support module enhances service. By streamlining processes, reducing redundancy, and ensuring data security, ERP increases profitability. Cloud deployment is cost-effective and scalable</p>
      </div>

      <div className="containerI2">
        <h2 className='HeadI2'>WAREHOUSE MANAGEMENT SYSTEM (WMS)
</h2>
        <p className='ParaI2'>The ERP system streamlines material handling, saving time in placing and picking materials across multiple locations. It categorizes warehouses based on different storage needs and temperature requirements, optimizing operational efficiency and resource utilization. Features like PutList and Stock retrieval modes enhance inventory management, while inter-location transfers allow for stock adjustments. The system offers seamless data import from SAP and manual entry options, along with comprehensive reporting, dashboards, and alerts. Export options to PDF/Excel formats facilitate data analysis. QR code stickers enable accurate material tracking via mobile devices, improving storage and retrieval accuracy.</p>
      </div>

      <div className="containerI3">
        <h2 className='HeadI3'>Software Consultancy And Coaching</h2>
        <p className='ParaI3'>Fivebrains specializes in professional Software Consultancy services, offering expert guidance to third parties for an optimized fee. This includes advisory and implementation services across various areas through our Business Associate program. Additionally, we provide valuable software training to enhance development skills, minimize errors, and boost overall productivity for businesses involved in software development, implementation, and maintenance.</p>
      </div>
    </div>
  );
}

export default Implementation;