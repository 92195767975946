import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Home from './Components/Home';
import WhoWeAre from './Components/WhoWeAre';
import WhatWeDo from './Components/WhatWeDo';
import Associates from './Components/Associates';
import KnowledgeBase from './Components/KnowledgeBase';
import Logo from './Header logo.png';
import "./Navigation.css";

const Navigation = () => {
    return (
        <BrowserRouter>
            <div className="navbar-container">
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/WhoWeAre" element={<WhoWeAre />} />
                    <Route path="/WhatWeDo" element={<WhatWeDo />} />
                    <Route path="/Associates" element={<Associates />} />
                    <Route path="/KnowledgeBase" element={<KnowledgeBase />} />
                </Routes>

                <nav className='horizontal-nav'>
                    <ul>
                        <li>
                            <NavLink exact to="/"><b>HOME</b></NavLink>
                        </li>
                        <li>
                            <NavLink to="/WhoWeAre"><b>WHO WE ARE ?</b></NavLink>
                        </li>
                        <li>
                            <NavLink to="/WhatWeDo"><b>WHAT WE DO ?</b></NavLink>
                        </li>
                        <li>
                            <NavLink to="/Associates"><b>ASSOCIATES</b></NavLink>
                        </li>
                        <li>
                            <NavLink to="/KnowledgeBase"><b>KNOWLEDGE BASE</b></NavLink>
                        </li>
                    </ul>
                    <button className="button-contact"><b>CONTACT US</b> <FontAwesomeIcon icon={faArrowRight} /></button>
                </nav>
                <div className="logo">
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
        </BrowserRouter>
    );
}
export default Navigation;